import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import styled, { css } from "styled-components"
import { harborBlue, isConstructionMode } from "../shared/consts"
import { handleNewline } from "../shared/utils"
import * as System from "../../design-system"
const Wrap = styled.div`
  width: 700px;
  margin: 0 auto;
  padding: 90px 55.623px;
  background: #2348a7;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 70px;
  overflow: hidden;
  @media (max-width: 1080px) {
    width: 90vw;
    padding: 50px 30.9px;
    display: inline-block;
    mix-blend-mode: multiply;
  }
`
const Column = styled.div`
  position: relative;
`
const ProuctItemTitle = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  font-size: 35px;
  line-height: 85.33%;
  letter-spacing: -0.05em;
  color: #ffffff;
`
const ProuctItemDescription = styled.div`
  font-family: NanumSquare;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-top: 10px;
`
const CallToActionLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
const CallToAction = styled.div`
  font-family: NanumSquare;
  font-weight: bold;
  font-size: 18px;
  line-height: 85.33%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-top: 60px;
  ${({ closed }) =>
    closed &&
    css`
      opacity: 0.3;
      pointer-events: none;
      cursor: not-allowed;
    `}
`
const ProuctItemIllust = styled.div`
  /* adjust Image component Wrap styles for centering */
  > div > div {
    position: absolute;
    /* This width make image size */
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 1080px) {
    > div > div {
      z-index: -1;
      filter: brightness(0.5);
    }
  }
`
const Mission = styled.div`
  max-width: 708px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
`
const ProuctItem = ({
  title,
  description,
  illustPath,
  to,
  outlink,
  closed,
}) => {
  return (
    <Wrap>
      <Column>
        <ProuctItemTitle>{handleNewline(title)}</ProuctItemTitle>
        <ProuctItemDescription>
          {handleNewline(description)}
        </ProuctItemDescription>
        {outlink ? (
          <a
            href={outlink}
            target="_blank"
            style={{
              textDecoration: "none",
            }}
          >
            <CallToAction closed={closed}>신청하기</CallToAction>
          </a>
        ) : (
          <CallToActionLink to={!closed ? to : ""}>
            <CallToAction closed={closed}>
              {!closed ? "신청하기" : "신청마감"}
            </CallToAction>
          </CallToActionLink>
        )}
      </Column>
      <Column>
        <ProuctItemIllust>
          <Image filename={illustPath} />
        </ProuctItemIllust>
      </Column>
    </Wrap>
  )
}
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <br />
      <Mission>
        <System.Paragraph
          style={{
            textAlign: "right",
          }}
          text="좋아하는 일들의 배를 띄워, 세상에 내 보이는일<br />그걸 돕는 스쿨이 하버입니다."
        />
      </Mission>
      <System.Spacing />
      <ProuctItem
        title={`Hello,
          Framer X`}
        description={`Framer 를 단계적으로 배울 수 있는
          국내 유일 Framer 정규 과정
          
          기초 / 중급반 으로 구성
          (루움 강사님 직강)`}
        to="/hello-framer-x"
        illustPath="index/illust-hf.png"
      />
      <ProuctItem
        title={`Framer
          Portfolio`}
        description={`웹사이트를 만들며 Framer 를
          가볍게 시작할 수 있는 초급 과정
          
          class101 x Harbor School`}
        // outlink="https://link.harbor.school/class101-framer"
        illustPath="index/illust-browser.png"
        closed
      />
      {!isConstructionMode && (
        <ProuctItem
          title={`Framer Korea
            Meetup`}
          description={`Framer 사용자들의
            자신들의 Framer 관련 이야기를 나누는 장`}
          to="/framer-korea-meetup"
          illustPath="index/illust-earth.png"
          closed
        />
      )}
    </Layout>
  )
}
export default IndexPage
